import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import { Button,Checkbox, Autocomplete, FormControl, Select, MenuItem,Card, InputLabel,Typography,CardContent, Grid, Tooltip,TextField,InputAdornment,Box} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Constant } from '../../Utilities/Constant';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { UcDatesFilter } from '../../Utilities/UcDatesFilter';
import { LoadingButton } from '@mui/lab';

export function UcPulseReport () {
    const previousController = useRef();
    const [startDate, setStartDate] = useState(dayjs(new Date()).add(-1, 'day').set('date', 1));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [fromdateerror, setFromDateError] = React.useState(0);
    const [todateerror, setToDateError] = React.useState(0);
    const [selectFilterError, setSelectFilterError] = React.useState(0);
    const [minfromdate, setMinFromDate] = React.useState('');
    const [maxfromdate, setMaxFromDate] = React.useState('');
    const [mintodate, setMinToDate] = React.useState('');
    const [maxtomdate, setMaxToDate] = React.useState('');
    const [datesRequired, setDatesRequired] = React.useState(true);
    const [searchtext, setsearchtext] = useState('');
    const [isDatafound, setIsDatafound] = React.useState(true);
    const [fisttimeload, setFirstTimeLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAllAccount, setSelectedAllAccount] = useState(null);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
    const [displayedBusinessUnits, setDisplayedBusinessUnits] = useState([]);
    const [errors, setErrors] = useState({});
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 0,
        pageSize: 10
    });

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails.groups);    
    const isAdmin = userinfo.includes('Admin');
    useEffect(() => {
        getCountryList();
    }, []);

    useEffect(() => {
        if(fisttimeload === false) 
        {
            if (searchtext.length >= 3) {
                getPulseReport(false);
            }
            else if (searchtext.length == '' || searchtext.length == 0 || searchtext.length == null) {
                getPulseReport(true);
            }
        }
    }, [searchtext])

    const handleChange = (e) => {
        setsearchtext(e.target.value);
    }

    useEffect(() => {
        setIsDatafound(true)
        if (fisttimeload === false)
         getPulseReport(true);
    }, [pageState.page, pageState.pageSize]);

    const getPulseReport = async (flag) => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;

        var IsValid = true;
        setFirstTimeLoad(false);  
        setLoading(true);
        setPageState(old => ({ ...old, isLoading: true }))
        if (IsNullOrEmptyOrUndefined(searchtext) === false && flag != true) {
          setPageState(old => ({ ...old, page: 0, pageSize: 10 }));
        }
        if (startDate === null || startDate === "" || startDate.isValid() === false || new Date(startDate).getFullYear() === 1970) {
            setFromDateError(1);
            IsValid = false;
        }
        if (endDate === null || endDate === "" || endDate.isValid() === false || new Date(endDate).getFullYear() === 1970) {
            setToDateError(1);
            IsValid = false;
        }
        if (startDate !== null && startDate !== "" && endDate !== null && endDate !== "") {
            if (startDate.add(1, 'year') < endDate) {
                CustomAlert('Please select date range of 1 year', 'error')
                setFromDateError(1);
                setToDateError(1);
                IsValid = false;
            }
        }
        if (IsValid){
            var parameters = { searchTerm: searchtext, pageSize: pageState.pageSize, pageNum: pageState.page===0?1:(pageState.page+1),
                countryId: selectedCountry, divisionId: selectedDivision, accountIds: selectedAllAccount, organizationalUnitIds: selectedBusinessUnit, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
            PostAPI(ApiURLs.PulseReport, parameters, null, signal).then((response) => {
                setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                CustomAlert(response.msg, 'error');
                }
            }
            else {
                setPageState(old => ({ ...old, isLoading: false, data: response, total: response.length > 0 ? response[0].totalCount : 0 }))
                if (response.length > 0) {
                    setIsDatafound(true);
                }
                else if (response.length === undefined) {
                    setPageState(old => ({ ...old, isLoading: true }))
                }
                else {
                    setIsDatafound(false);
                }
            }
            })
            .catch((e) => {
                setLoading(false);
                setPageState(old => ({ ...old, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 }));
                CustomAlert(e, 'error');
            });
        }
        else {
            setLoading(false);
            setPageState(old => ({ ...old, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 }));
        }
    };
      
    const getCountryList = () => {
    setLoading(true);
    var url = ApiURLs.ReportCountryList; 
    GetAPI(url).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert(response.msg, 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            setCountryList(response);
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    };
    const getDivisionList = () => {
        setLoading(true);
        var url = ApiURLs.ReportDivisionList;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setDivisionList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const getAccountList = (selectedDivisionValue) => {
        setLoading(true);
        var url = ApiURLs.ReportAccountList + '?countryId=' + selectedCountry + '&divisionId=' + selectedDivisionValue;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setAccountList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const getBusinessUnitList = (selectedAccountValue) => {
        setLoading(true);
        var parameters = { countryId: selectedCountry, divisionId: selectedDivision, accountIds: selectedAccountValue };
        PostAPI(ApiURLs.ReportBusinessUnitList, parameters).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('All business units are mapped', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setBusinessUnitList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const handleCountrySelectChange = (event) => {
        if (errors.selectedCountry) {
            delete errors.selectedCountry;
        }
        const selectedValue = event.target.value;
        setSelectedCountry(selectedValue);
        if (selectedValue && selectedValue !== ' ') {
            getDivisionList();
        }
    }; 
    const handleDivisionSelectChange = (event) => {
        if (errors.selectedDivision) {
            delete errors.selectedDivision;
        }
        const selectedDivisionValue = event.target.value;
        setSelectedDivision(selectedDivisionValue);
        setAccountList([]);
        setSelectedAccount(null);
        setSelectedAllAccount([]);
        setSelectedBusinessUnit([]);
        setDisplayedBusinessUnits([]);
        setBusinessUnitList([]);
        if (selectedDivisionValue && selectedDivisionValue !== ' ') {
            getAccountList(selectedDivisionValue);
        }
    };
    const handleAccountSelectChange = (event, newValue) => {
        if (!newValue) {
            setSelectedAllAccount([]);
            setSelectedAccount(null);
            setSelectedBusinessUnit([]);
            setDisplayedBusinessUnits([]);
            setBusinessUnitList([]);
            return;
        }
        
        let selectedValues = newValue;
        
        if (!Array.isArray(selectedValues)) {
            selectedValues = [selectedValues]; 
        }
    
        const isAllSelected = selectedValues.some(item => item.accountName === 'ALL Accounts');
    
        let accountIds = [];
        if (isAllSelected) {
            const allValuesExceptAll = accountList.filter(item => item.accountName !== 'ALL Accounts');
            accountIds = allValuesExceptAll.map(item => item.accountId);
            setSelectedAllAccount(accountIds);
            setSelectedAccount({ accountId: 1, accountName: 'ALL Accounts' });
        } else {
            accountIds = selectedValues.map(item => item.accountId);
            setSelectedAllAccount(accountIds);
            setSelectedAccount(newValue);
        }

        setSelectedBusinessUnit([]);
        setDisplayedBusinessUnits([]);
        setBusinessUnitList([]);
        if (accountIds.length > 0) {
            getBusinessUnitList(accountIds); 
        }
    };
    const handleBusinessUnitChange = (event, newValue) => {
        const uniqueBusinessUnitIds = Array.from(new Set(newValue.map(option => option.id)));
        setSelectedBusinessUnit(uniqueBusinessUnitIds);
    
        const uniqueBusinessUnits = uniqueBusinessUnitIds.map(id =>
            newValue.find(option => option.id === id)
        );
        setDisplayedBusinessUnits(uniqueBusinessUnits);
    };
    const onSubmit = () => {
        const newErrors = {};
        if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
        if (!selectedDivision) newErrors.selectedDivision = 'Division is required';
        
        setErrors(newErrors);
  
      if (Object.keys(newErrors).length === 0) {
        setLoading(true);   
        getPulseReport();
      }
        
    };

    const columns = [
        //{ field: 'id', headerName: 'Id',  hide:true},
        {
            field: 'country', headerName: 'Country', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.country}>
               <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                 {IsNullOrEmptyOrUndefined(cell.row.country) === true ? "" : cell.row.country}
                </Typography></Tooltip>);
            },
        },
        {
          field: 'division', headerName: 'Division', headerAlign: 'left', align: 'left', sortable: false, width: 200,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.division}>
               <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.division) === true ? "" : cell.row.division}
                </Typography></Tooltip>);
          },
        },
        {
            field: 'account', headerName: 'Account', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.account}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.account) === true ? "" : cell.row.account}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'organizationalUnit', headerName: 'Business Unit', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.organizationalUnit}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.organizationalUnit) === true ? "" : cell.row.organizationalUnit}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'businessHead', headerName: 'Business Head', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.businessHead}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.businessHead) === true ? "" : cell.row.businessHead}
                </Typography></Tooltip>);
            },
        },
        ...((isAdmin) ? [
        {
            field: 'employeeId', headerName: 'Employee Id', headerAlign: 'left', align: 'left', sortable: false, width: 120,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.employeeId}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.employeeId) === true ? "" : cell.row.employeeId}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'employeeName', headerName: 'Employee Name', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.employeeName}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.employeeName) === true ? "" : cell.row.employeeName}
                </Typography></Tooltip>);
            },
        },
        ] : []),
        {
            field: 'teamManager', headerName: 'Team Manager', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.teamManager}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.teamManager) === true ? "" : cell.row.teamManager}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'band', headerName: 'Band', headerAlign: 'left', align: 'left', sortable: false, width: 80,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.band}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.band) === true ? "" : cell.row.band}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'tenure', headerName: 'Tenure', headerAlign: 'left', align: 'left', sortable: false, width: 120,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.tenure}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.tenure) === true ? "" : cell.row.tenure}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'location', headerName: 'Location', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.location}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.location) === true ? "" : cell.row.location}
                </Typography></Tooltip>);
            },
        },
        {
            field: 'feedbackDate', headerName: 'Feedback Date', headerAlign: 'left', align: 'left', sortable: false, width: 120,
            renderCell: (cell) => {
                return (<Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.feedbackDate) === false ? dayjs(cell.row.feedbackDate).format(Constant.dateformat) : ""}</Typography>);
            },
        },
        {
            field: 'moodType', headerName: 'Mood Type', headerAlign: 'left', align: 'left', sortable: false, width: 100,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.moodType}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.moodType) === true ? "" : cell.row.moodType}</Typography></Tooltip>);
            },
        }
    ] 

    return (
        <>
        <Card variant="outlined" style={{ marginTop: 10 }} >
            <CardContent style={{ display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={3} >
                    <UcDatesFilter datesRequired = {datesRequired} minfromdate={minfromdate} maxfromdate={maxfromdate} mintodate={mintodate} maxtomdate={maxtomdate} setFromDateError={setFromDateError} fromDateError={fromdateerror}
                        setToDateError={setToDateError} toDateError={todateerror} fromDate={startDate} setFromDate={setStartDate} toDate={endDate} setToDate={setEndDate} ></UcDatesFilter>
                </Grid>  
                <Grid item xs={12} sm={2} marginTop={'10px'}>
                    <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedCountry} >
                    <InputLabel>Country</InputLabel>
                    <Select
                        value={selectedCountry}
                        onChange={handleCountrySelectChange}
                        label="Country"
                    >
                        <MenuItem value="" disabled>Select Country</MenuItem>
                        {countryList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.country}</MenuItem>
                        ))}
                    </Select>
                    {errors.selectedCountry && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedCountry}</div>}
                    </FormControl>
                </Grid>  
                <Grid item xs={12} sm={2} marginTop={'10px'}>
                    <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedDivision}>
                    <InputLabel>Division</InputLabel>
                    <Select
                        value={selectedDivision}
                        onChange={handleDivisionSelectChange}
                        label="Division"
                    >
                        <MenuItem value="" disabled>Select Division</MenuItem>
                        {divisionList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.division}</MenuItem>
                        ))}
                    </Select>
                    {errors.selectedDivision && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedDivision}</div>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} marginTop={'10px'}>
                <Autocomplete
                    id="tags-outlined"
                    size="small"
                    options={accountList}
                    getOptionLabel={(option) => option.accountName || ''} 
                    filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Account"
                        placeholder="Account"
                    />
                    )}
                    value={selectedAccount}
                    onChange={handleAccountSelectChange}
                />                   
                </Grid>
                <Grid item xs={12} sm={2} marginTop={'10px'}>
                <Autocomplete
                    multiple                 
                    size="small" 
                    id="checkboxes-tags-demo"
                    options={businessUnitList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.organizationalUnit}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.organizationalUnit}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField 
                        {...params} label="Business Unit" placeholder="Search..." />
                    )}
                    value={displayedBusinessUnits}
                    onChange={handleBusinessUnitChange}
                />
                </Grid>
                <Grid item xs={12} sm={1} marginTop={'10px'}>
                <LoadingButton loading={loading}
                    variant="contained"
                    style={{ textTransform: 'none', fontSize: 15, width: 100 }}
                    onClick={onSubmit}
                    className="App-Button"
                    >
                    Submit
                </LoadingButton>
                </Grid>
                </Grid>
            </CardContent>
        </Card>
            <Card variant='outlined' style={{ marginTop: 5, marginBottom: 15, minHeight: 350 }}>              
            <div style={{ paddingLeft: '80%', paddingBottom: '1%' }}>
                <TextField style={{ width: '80%' }}
                    placeholder='Search...'
                    value={searchtext}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className="App-Icons"/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
        {(pageState.data.length > 0 || pageState.data.length === undefined) &&
            <Box sx={{ height: 350, width: '100%' }}>
                    <DataGrid
                    getRowId={(row) => row.id}
                    disableColumnMenu
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pageSizeOptions={[10, 30, 50, 70, 100]}
                    pagination                    
                    paginationMode="server"
                    onPaginationModelChange={(e) => {
                    setPageState(old => ({ ...old, page: e.page, pageSize:e.pageSize}))
                    }}
                    paginationModel={pageState}
                    columns={columns}
                    getRowHeight={() => 'auto'} headerHeight={31}
                    className="App-Icons"
                    sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 } }}
                />
            </Box>
        }
        {isDatafound === false &&
            <Box sx={{ height: 250, width: '100%', paddingTop: 20 }}>
                <div sx={{ fontSize: 13, align: 'center' }}>
                    <center>No record found</center>
                </div>
            </Box>
        }   
        </Card>
        </>
    );
}