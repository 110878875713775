import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Drawer, Box,List, ListItem, ListItemText, Collapse, TextField, Toolbar, InputAdornment,Divider } from '@mui/material';
import { ClearAll, ExpandLess, ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from '@mui/material/ListItemIcon';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { GetAPI } from '../Utilities/API/GetAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';


export default function DashboardSidebar(props) {
    dayjs.extend(relativeTime);
    const [openDivisions, setOpenDivisions] = useState({});
    const [openAccounts, setOpenAccounts] = useState({});
    const [search, setSearch] = useState("");
    const [lastSearch, setLastSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [navList, setNavList] = useState([]);
    const [startDate, setStartDate] = useState(dayjs(new Date()).add(-1, 'day').set('date', 1));
    const [endDate, setEndDate] = useState(dayjs(new Date()));

    const division = useSelector((state) => state.userDetailsReducer.userDetails.division);
    const initialStyle = {
        top: '64px',
        height: '100%',
        padding: '5px',
        width: '240px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white', 
        transition: 'top 0.1s ease',// Initial style
    };
    const [drawerStyle, setDrawerStyle] = useState(initialStyle);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            // Update the style based on scroll position
            if (scrollY > 90) {
                setDrawerStyle({
                    ...drawerStyle,
                    top:'0px;', // Example of style change on scroll down
                });
            } else {
                setDrawerStyle(initialStyle); // Reset to initial style on scroll up
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [drawerStyle]);

    useEffect(() => {
        getUserNavigationList();
    }, []);


    useEffect(() => {
        if (navList.length > 0 && division) {
          const matchedDivision = navList.find((div) => div.divisionName === division);
          if (matchedDivision) {
            setOpenDivisions({ [matchedDivision.divisionId]: true });
            setLastSearch('d-' + matchedDivision.divisionId);
            props.setFilterModel({
              selectedCountry: matchedDivision.countryId,
              selectedDivision: matchedDivision.divisionId,
              selectedAccount: null,
              selectedBusinessUnit: null,
              selectedBusinessHead: [],
              selectedBand: [],
              startDate: startDate,
              endDate: endDate,
              ClearAll: true,
              selectedPieChartName: matchedDivision.divisionName,
            });
          }
        }
      }, [navList, division]);

    const getUserNavigationList = () => {
        setLoading(true);
        const url = ApiURLs.UserNavigation;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                CustomAlert(response.msg, 'error');
            } else {
              setNavList(response.divisions || []);
            }
        })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    };

    const handleSearch = (event) => {
        const newSearch = event.target.value;
        setSearch(newSearch);

        const lowercasedFilter = newSearch.toLowerCase();
        const expandedDivisions = {};
        const expandedAccounts = {};

        if (lowercasedFilter) {
            navList.forEach((division) => {
                let shouldExpandDivision = false;

                (division.accounts || []).forEach((account) => {
                    const shouldExpandAccount = account.accountName.toLowerCase().includes(lowercasedFilter) || 
                        (account.businessUnits || []).some(bu => bu.organizationalUnit.toLowerCase().includes(lowercasedFilter));

                    if (shouldExpandAccount) {
                        expandedAccounts[account.accountId] = true;
                        shouldExpandDivision = true;
                    }
                });

                // Check if division matches the search filter
            if (division.divisionName.toLowerCase().includes(lowercasedFilter)) {
                expandedDivisions[division.divisionId] = true;
                shouldExpandDivision = true;
            }

            // Check if otherBusinessUnits match the search filter
            if ((division.otherBusinessUnits || []).some(bu => bu.organizationalUnit.toLowerCase().includes(lowercasedFilter))) {
                expandedDivisions[division.divisionId] = true;
                shouldExpandDivision = true;
            }

            // Expand division if any related accounts or otherBusinessUnits match
            if (shouldExpandDivision) {
                expandedDivisions[division.divisionId] = true;
            }
            });
        }

        setOpenDivisions(expandedDivisions);
        setOpenAccounts(expandedAccounts);
    };

    const filteredDivisions = () => {
        const lowercasedFilter = search.toLowerCase();
   
        return navList
            .map((division) => {
                const filteredAccounts = (division.accounts || [])
                    .map((account) => {
                        const filteredBuList = (account.businessUnits || []).filter((bu) =>
                            bu.organizationalUnit.toLowerCase().includes(lowercasedFilter)
                        );
                        if (account.accountName.toLowerCase().includes(lowercasedFilter) || filteredBuList.length > 0) {
                            return { ...account, businessUnits: filteredBuList };
                        }
                        return null;
                    })
                    .filter((account) => account !== null);
    
                const filteredOtherBusinessUnits = (division.otherBusinessUnits || [])
                    .filter((bu) =>
                        bu.organizationalUnit.toLowerCase().includes(lowercasedFilter)
                    );
    
                if (
                    division.divisionName.toLowerCase().includes(lowercasedFilter) ||
                    filteredAccounts.length > 0 ||
                    filteredOtherBusinessUnits.length > 0
                ) {
                    return {
                        ...division,
                        accounts: filteredAccounts.length > 0 ? filteredAccounts : null,
                        otherBusinessUnits: filteredOtherBusinessUnits,                    
                    };
                }
               return null;
            })
            .filter((division) => division !== null);
    };
    const toggleDivision = (countryId,divisionId) => {
        setLastSearch('d-'+ divisionId)
        setOpenDivisions((prevOpen) => ({
            ...prevOpen,
            [divisionId]: !prevOpen[divisionId]
        }));
        const matchedDivision = navList.find((div) => div.divisionId === divisionId);
        props.setFilterModel({
            selectedCountry: countryId,
            selectedDivision: divisionId,
            selectedAccount: null,
            selectedBusinessUnit: null,
            selectedBusinessHead: [],
            selectedBand: [],
            startDate: startDate,
            endDate: endDate,
            ClearAll: true,
            selectedPieChartName: matchedDivision.divisionName,
        })
    };

    const toggleAccount = (divisionId,accountId) => {
        setLastSearch('a-'+ accountId)
        setOpenAccounts((prevOpen) => ({
            ...prevOpen,
            [accountId]: !prevOpen[accountId]
        }));
        const matchedAccountName = navList
                                    .find(div => div.divisionId === divisionId)?.accounts
                                    .find(acc => acc.accountId === accountId)?.accountName;
        props.setFilterModel((prevFilterModel) => ({
            ...prevFilterModel,
            selectedDivision: divisionId,
            selectedAccount: accountId,
            selectedBusinessUnit: null,
            selectedBusinessHead: [],
            selectedBand: [],
            startDate: startDate,
            endDate: endDate,
            ClearAll: true,
            selectedPieChartName: matchedAccountName,
        }));
    };

    const handleBusinessUnitClick = (divisionId,accountId,bu) => {
        setLastSearch('bu-' + bu.id);
        const matchedOrganizationalUnitName = navList
                                            .find(div => div.divisionId === divisionId)?.accounts
                                            .find(acc => acc.accountId === accountId)?.businessUnits
                                            .find(bus => bus.id === bu.id)?.organizationalUnit;
        props.setFilterModel((prevFilterModel) => ({
            ...prevFilterModel,
            selectedDivision: divisionId,
            selectedAccount: accountId,
            selectedBusinessUnit: bu.id,
            selectedBusinessHead: [],
            selectedBand: [],
            startDate: startDate,
            endDate: endDate,
            ClearAll: true,
            selectedPieChartName: matchedOrganizationalUnitName,
        }));
    };
    const handleOtherBusinessUnitClick = (divisionId,bu) => {
        setLastSearch('obu-'+ + bu.id);
        const matchedOtherOrganizationalUnitName = navList
                                            .find(div => div.divisionId === divisionId).otherBusinessUnits
                                            .find(bus => bus.id == bu.id).organizationalUnit;                                
         props.setFilterModel((prevFilterModel) => ({
        ...prevFilterModel,
        selectedDivision: divisionId,
        selectedAccount: null,
        selectedBusinessUnit: bu.id,
        selectedBusinessHead: [],
        selectedBand: [],
        startDate: startDate,
        endDate: endDate,
        ClearAll: true,
        selectedPieChartName: matchedOtherOrganizationalUnitName,
    }));
        
    };

    return (
        <>
            
            <Toolbar />
            <Drawer
                variant="permanent"
                anchor="left"
               
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                
                <TextField
                    variant="outlined"
                    size='small'
                    placeholder="Search..."
                    fullWidth
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: search && (
                            <InputAdornment position="end">
                                <CloseIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSearch("");
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            borderRadius: '6px',
                        },
                    }}
                    style={{
                        marginBottom: '8px',
                    }}
                />
                <Box
                    sx={{
                        flex: '1',
                        overflowY: 'auto',
                    }}
                >
                <List>
                    {filteredDivisions().length === 0 ? (
                        <ListItem>
                            <ListItemText primary="No records found" />
                        </ListItem>
                    ) : (
                        filteredDivisions().map((division) => (
                            <div key={division.divisionId}>
                                <ListItem button onClick={() => toggleDivision(division.countryId,division.divisionId)}                               
                                    sx={lastSearch === 'd-' + division.divisionId ? {backgroundColor: '#f0f0f0'} : {}}>
                                    <ListItemIcon sx={{ minWidth: '24px' }}>
                                        <AccountBalanceIcon className='App-Icons' sx={{fontSize: "18px" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={division.divisionName} />
                                    {openDivisions[division.divisionId] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={openDivisions[division.divisionId]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {division.accounts && division.accounts.length > 0 && (
                                            <>
                                                {division.accounts.map((account) => (
                                                    <div key={account.accountId}>
                                                        <ListItem
                                                            button
                                                            onClick={() => toggleAccount(division.divisionId,account.accountId)}                                                         
                                                            sx={
                                                                lastSearch === 'a-' + account.accountId ? {backgroundColor: '#f0f0f0',paddingLeft: '24px',
                                                                marginBottom: openAccounts[account.accountId] ? '8px' : '0'} : {paddingLeft: '24px',
                                                                marginBottom: openAccounts[account.accountId] ? '8px' : '0'}                                          }
                                                        >
                                                            <ListItemIcon sx={{ minWidth: '24px' }}>
                                                                <AccountTreeIcon className='App-Icons' sx={{fontSize: "15px" }} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={account.accountName} />
                                                            {openAccounts[account.accountId] ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>
                                                        <Collapse in={openAccounts[account.accountId]} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {account.businessUnits &&
                                                                    account.businessUnits.map((bu) => (
                                                                        <ListItem
                                                                            button
                                                                            key={bu.id}
                                                                            onClick={() => handleBusinessUnitClick(division.divisionId,account.accountId,bu)}
                                                                                sx={lastSearch === 'bu-' + bu.id ? {
                                                                                    backgroundColor: '#f0f0f0', paddingLeft: '48px',
                                                                                } : {
                                                                                    paddingLeft: '48px',
                                                                                   
                                                                                }}
                                                                        >
                                                                            <ListItemIcon sx={{ minWidth: '24px' }}>
                                                                                <RadioButtonUncheckedIcon className='App-Icons' sx={{fontSize: "10px" }} />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={bu.organizationalUnit} />
                                                                        </ListItem>
                                                                    ))}
                                                            </List>
                                                        </Collapse>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                        {division.otherBusinessUnits && division.otherBusinessUnits.length > 0 && (
                                            <div>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                    <Divider sx={{ marginLeft: '24px', marginRight: '16px' }} />
                                                        {division.otherBusinessUnits.map((bu) => (
                                                            <ListItem
                                                            button
                                                            key={bu.id}
                                                            onClick={() => handleOtherBusinessUnitClick(division.divisionId,bu)}
                                                            sx={lastSearch === 'obu-' + bu.id ? {
                                                                backgroundColor: '#f0f0f0', paddingLeft: '24px'
                                                            } : {
                                                                paddingLeft: '24px'
                                                            }}
                                                        >
                                                                <ListItemIcon sx={{ minWidth: '24px' }}>
                                                                    <RadioButtonUncheckedIcon className='App-Icons' sx={{ fontSize: "10px" }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={bu.organizationalUnit} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )}
                                    </List>
                                </Collapse>
                            </div>
                        ))
                    )}
                </List>
                </Box>
            </Drawer>
            
        </>
    );
}
