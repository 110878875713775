import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SentimentSatisfiedOutlined, KeyboardArrowDown, Logout, FileDownloadOutlined } from '@mui/icons-material';
import { Button, AppBar, Toolbar, Box, Menu, MenuItem, ListItemIcon, ListItemText, Divider, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import '..//..//src/App.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { msalInstance } from "../index";
import { ToastContainer } from 'react-toastify';
import { connect, useSelector } from 'react-redux'
import AddReactionIcon from '@mui/icons-material/AddReaction';
import Pulsemeterlogo from '../layout/PulseMeterLogo.png'
import { ProxyLogin } from '../ui-component/Login/ProxyLogin';
import ProfilePicture from '../layout/ProfilePicture';
import AllowNotification from '../layout/AllowNotification'

export function NavMenu(props) {
    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);
    var admin = userinfo.groups.filter(o => o === 'Admin' || o === 'HR');
    const account =  userinfo.firstname + " " + userinfo.lastname;
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const CheckProxyLogin = () => {
        const IsproxyUser = userinfo.allowProxy;
        return IsproxyUser == 1 ? <ProxyLogin /> : <div></div>; 
    }
    

    const handleClose = (btnClicked) => {

        switch (btnClicked) {
            case 'Dashboard':
                navigate('/Dashboard'); break;
            case 'Feedback':
                navigate('/Home'); break;
            case 'Actions':
                navigate('/Actions'); break;
            case 'Report':
                navigate('/Report'); break;
            case 'Logout':
                msalInstance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                  });
                break;
            default:
                setAnchorEl(null); break;
        }
        setAnchorEl(null);
    };
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="static" elevation={0} sx={{ backgroundColor: 'white ' }}>
                    <Toolbar style={{ minHeight: 55 }}>
                        {/* <SentimentSatisfiedOutlined sx={{ fontSize: 35, color: 'orange' }} /> */}
                        <Link to="/Home" style={{ textDecoration: 'none' }}>
                        <img src={Pulsemeterlogo} alt='Logo'
                         style={{ 
                            marginTop: '4px',
                            width: '180px', 
                            height: '55px', 
                            transition: 'transform 0.3s', // Add transition for smooth zoom-in
                            transform: 'scale(1.3)', // Default scale
                        }} 
                        ></img>
                        {/* <Typography style={{ paddingLeft: 5 }} variant="h6" component="div">PulseMeter</Typography> */}

                        </Link>
                        <div style={{ flexGrow: 1 }}></div>
                        
                        <div>
                            <CheckProxyLogin/>
                        </div>
                        <AllowNotification></AllowNotification>
                        {admin.length > 0 && <>
                            <div style={{ marginRight: 20 }}></div>
                            <IconButton><FileDownloadOutlined className="App-Icons" /></IconButton></>
                        }
                        <div style={{ marginRight: 20 }}></div>
                        <ProfilePicture />
                        {admin.length > 0 &&
                            <Button disableElevation style={{ textTransform: 'none', fontSize: 15, fontWeight: 600 }} onClick={handleClick} endIcon={<KeyboardArrowDown className="App-Icons" />}>
                                <Typography className="App-FontColor" sx={{ flexGrow: 1 }}>
                                    Hi, {account}
                                </Typography>

                            </Button>}
                        {!admin.length > 0 &&
                        <Typography className="App-FontColor" style={{ textTransform: 'none', fontSize: 15, fontWeight: 600 }}>
                           Hi, {account}
                        </Typography>}

                    </Toolbar>
                    <Divider />

                </AppBar>
            </Box>

            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid #d9d9d9' } }} anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                <MenuItem onClick={() => handleClose('Feedback')}>
                    <ListItemIcon><AddReactionIcon fontSize="small" className="App-Icons" /></ListItemIcon>
                    <ListItemText className="App-FontColor">Feedback</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('Dashboard')}>
                    <ListItemIcon>
                        <DashboardIcon fontSize="small" className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Dashboard</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('Report')}>
                    <ListItemIcon>
                        <AssessmentIcon fontSize="small"  className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Report</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('Actions')}>
                    <ListItemIcon>
                        <PendingActionsIcon fontSize="small" className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Actions</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('Logout')}>
                    <ListItemIcon>
                        <Logout fontSize="small" className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Logout</ListItemText>
                </MenuItem>
            </Menu>
            <ToastContainer />
        </>
    );
}