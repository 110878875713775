import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Card, Tooltip,Switch, Button,TextField, Divider,Grid, Typography,Box,InputLabel, MenuItem, Select,FormControl,Paper,List,ListItemButton,ListItemIcon,Checkbox,ListItemText} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PutAPI } from '../../Utilities/API/PutAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import '../../../src/App.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UcAccountMappingUpdate(props) {
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
    const [accountName, setAccountName] = useState('');
    const [accountId, setAccountId] = useState(0);
    const label = { inputProps: { 'aria-label': 'Enable/Disable' } };
    const [checked, setChecked] = useState(true);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const newErrors = {};
 
    useEffect(() => {
        getCountryList();
    }, [selectedCountry]);
  
    useEffect(() => {    
        if (props.data.countryId !== 0 && selectedCountry === '') { 
            setAccountId(props.data.accountId || 0);
            setAccountName(props.data.accountName || '');
            setSelectedCountry(props.data.countryId || '');
            if(props.data.active === 'Inactive')
            {
                setChecked(false);
            }
            else
            {
                setChecked(true);
            }
            getDivisionList(props.data.countryId);
        }
      }, [props.data.countryId]);
  
      useEffect(() => {    
        if(props.data.divisionId !== 0 && selectedDivision === ''){ 
          setSelectedDivision(props.data.divisionId || '');
          getBusinessUnitList(props.data.accountId,props.data.countryId,props.data.divisionId);
        }
      }, [props.data.divisionId ]);

      useEffect(() => {
        if (props.data.organizationalUnitId.length > 0 && selectedBusinessUnit.length === 0) {
            setSelectedBusinessUnit(props.data.organizationalUnitId);
        }
    }, [props.data.organizationalUnitId]);
      
    const getCountryList = () => {
    setLoading(true);
    var url = ApiURLs.AccountCountryList; 
    GetAPI(url).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert(response.msg, 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            setCountryList(response);
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    };
    const getDivisionList = (selectedValue) => {
    setLoading(true);
    var url = ApiURLs.AccountDivisionList + '?countryId=' + selectedValue;
    GetAPI(url).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert(response.msg, 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            setDivisionList(response);
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    };
    const getBusinessUnitList = (selectedAcoountValue,selectedCountryValue,selectedDivisionValue) => {
        setLoading(true);  
        var url = ApiURLs.AccountBusinessUnitList +'?accountId=' + selectedAcoountValue +'&countryId=' + selectedCountryValue +'&divisionId=' + selectedDivisionValue;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setBusinessUnitList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const updateBUAccountDetails = () => {
        setLoading(true);
        var url = ApiURLs.UpdateBUAccountDetails;
        var model = { accountName:accountName, countryId:selectedCountry, divisionId:selectedDivision, organizationalUnitList: selectedBusinessUnit, accountId:accountId,active:checked ? 1 : 0 };
        PutAPI(url, model).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                CustomAlert(response.message, 'success'); 
                props.onClose();         
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      
    };
    const handleCountrySelectChange = (event) => {
        if (errors.selectedCountry) {
            delete errors.selectedCountry;
        }
        const selectedValue = event.target.value;
        
        if (selectedCountry !== selectedValue) {
            setSelectedCountry(selectedValue);
            setSelectedDivision('');
            setSelectedBusinessUnit([]);
            setDivisionList([]);
            setBusinessUnitList([]);

            if (selectedValue && selectedValue !== ' ') {
                getDivisionList(selectedValue);
            }
        }
    }; 
    const handleDivisionSelectChange = (event) => {
        if (errors.selectedDivision) {
            delete errors.selectedDivision;
        }
        const selectedDivisionValue = event.target.value;
        setSelectedDivision(selectedDivisionValue);
        setSelectedBusinessUnit([]);
        setBusinessUnitList([]);
        if (selectedDivisionValue && selectedDivisionValue !== ' ') {
            getBusinessUnitList(accountId,selectedCountry,selectedDivisionValue);
        }
    };
    const handleBusinessUnitChange = (event, newValue) => {
        if (errors.selectedBusinessUnit) {
            delete errors.selectedBusinessUnit;
        }
        // Create a set to ensure uniqueness
        const uniqueBusinessUnits = Array.from(new Set(newValue.map((option) => option.id)))
            .map((id) => {
                return newValue.find((option) => option.id === id);
            });
    
        setSelectedBusinessUnit(uniqueBusinessUnits);
    };
    const errorStyle = {
        borderColor: 'red',
        borderWidth: 1,
    };
    const onSubmit = () => {
        const newErrors = {};
        if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
        if (!selectedDivision) newErrors.selectedDivision = 'Division is required';
        if (!accountName) newErrors.accountName = 'Account Name is required';
        if ((!selectedBusinessUnit || selectedBusinessUnit.length === 0) && checked)
            newErrors.selectedBusinessUnit = 'Business Unit is required';
  
        setErrors(newErrors);
  
      if (Object.keys(newErrors).length === 0) {
            updateBUAccountDetails();
      }
    };
    const handleAccountName = (e) => {
        const { value } = e.target;
        const newErrors = {};
        // Validate if the input only contains letters and spaces
        const regex = /^[A-Za-z0-9\s]*$/;
        if (!regex.test(value)) {
          newErrors.accountName = 'Only alphanumeric characters and spaces are allowed';
        } else if (value.length > 200) {
          newErrors.accountName = 'Character limit is exceeded';
        } else {
          setAccountName(value);
        }   
        setErrors(newErrors);
    };
    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
    };
  return (
    <>
       <Box p={3} >
            <Grid container justifyContent="flex-end">
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
                <CloseIcon className='App-Icons'/>
                </IconButton>
            </Grid>
            <Typography variant="h6" component="h6">
                Update Account
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{marginTop:2}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    fullWidth
                    value={accountName}
                    onChange={handleAccountName}
                    label="Account Name"
                    variant="outlined"
                    size="small"
                    required   
                    error={!!errors.accountName}
                    helperText={errors.accountName}
                    style={errors.accountName ? errorStyle : {}}               
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedCountry}>
                    <InputLabel style={errors.selectedCountry ? errorStyle : {}}>Country</InputLabel>
                    <Select
                        value={selectedCountry}
                        onChange={handleCountrySelectChange}
                        label="Country"  
                        style={errors.selectedCountry ? errorStyle : {}}                   
                    >
                        <MenuItem value="" disabled>Select Country</MenuItem>
                        {countryList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.country}</MenuItem>
                        ))}
                    </Select>
                    {errors.selectedCountry && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedCountry}</div>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedDivision}>
                    <InputLabel style={errors.selectedDivision ? errorStyle : {}}>Division</InputLabel>
                    <Select
                        value={selectedDivision}
                        onChange={handleDivisionSelectChange}
                        label="Division"
                        style={errors.selectedDivision ? errorStyle : {}}
                    >
                        <MenuItem value="" disabled>Select Division</MenuItem>
                        {divisionList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.division}</MenuItem>
                        ))}
                    </Select>
                    {errors.selectedDivision && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedDivision}</div>}
                    </FormControl>
                </Grid> 
                <Grid item xs={12} sm={6}>
                <Autocomplete
                    multiple                 
                    size="small" 
                    id="checkboxes-tags-demo"
                    options={businessUnitList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.organizationalUnit}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            {option.organizationalUnit}
                        </li>
                        );
                    }}
                   
                    renderInput={(params) => (
                        <TextField required 
                        error={!!errors.selectedBusinessUnit}
                        helperText={errors.selectedBusinessUnit}
                        style={errors.selectedBusinessUnit ? errorStyle : {}}
                        {...params} label="Business Unit" placeholder="Search..." />
                    )}
                    value={selectedBusinessUnit}
                    onChange={handleBusinessUnitChange}
                    />
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <Tooltip title='Active/Inactive'>
                    <Switch {...label} checked={checked} onChange={handleSwitchChange} />
                    </Tooltip>
                    <Button
                    variant="contained"
                    style={{ textTransform: 'none', fontSize: 15, width: 120 }}
                    onClick={onSubmit}
                      className="App-Button"
                    >
                    Update
                    </Button>
                </Grid>      
            </Grid>             
            <Typography style={{ fontSize: 12,fontStyle: 'Italic',color:'red',marginTop: 30}}>
                Note: The Business Unit mapping will be removed for any account that is disabled.
            </Typography>      
            
        </Box>
        </>
  );
}