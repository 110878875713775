import { GetAPI } from '../Utilities/API/GetAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import React, { useState, useEffect } from 'react';
import { Card,CardContent, Button, Grid, Autocomplete, TextField } from '@mui/material';
import { UcDatesFilter } from '../Utilities/UcDatesFilter';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PostAPI } from '../Utilities/API/PostAPI';

export default function DashboardFilters(props) {
    dayjs.extend(relativeTime);
    const [startDate, setStartDate] = useState(dayjs(new Date()).add(-1, 'day').set('date', 1));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [fromdateerror, setFromDateError] = React.useState(0);
    const [todateerror, setToDateError] = React.useState(0);
    const [selectFilterError, setSelectFilterError] = React.useState(0);
    const [minfromdate, setMinFromDate] = React.useState('');
    const [maxfromdate, setMaxFromDate] = React.useState('');
    const [mintodate, setMinToDate] = React.useState('');
    const [maxtomdate, setMaxToDate] = React.useState('');
    const [disableFutureDates, setDisableFutureDates] = React.useState(true);
    const [businessHeadList, setBusinessHeadList] = React.useState([]);
    const [selectedBusinessHead, setSelectedBusinessHead] = React.useState([]); 
    const [selectAllBUHead, setSelectAllBUHead] = useState([]);
    const [bandList, setBandList] = React.useState([]);
    const [selectedBand, setSelectedBand] = React.useState([]);
    const [selectAllBand, setSelectAllBand] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clear, setclear] = useState(false);
 
    
    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;
    var PieChartName = props.filterModel.selectedPieChartName;

    useEffect(() => {
        if(props.filterModel.ClearAll === true && clear === false) {
            setclear(true);
            onClear();
        }
        setclear(false);
    }, [props.filterModel]);

    useEffect(() => {
        if(countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null ) {
            getBusinessUnitHeadList();
            getEmployeeBandList();        
        }
    }, [props.filterModel]);


    const getBusinessUnitHeadList = () => {
        setLoading(true);
        var parameters = {countryId: countryId, divisionId: divisionId, accountId: accountId, organizationalUnitId: organizationalUnitId};
        PostAPI(ApiURLs.BusinessUnitHeadList, parameters ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setBusinessHeadList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const getEmployeeBandList = () => {
        setLoading(true);
        var parameters = {countryId: countryId, divisionId: divisionId, accountId: accountId, organizationalUnitId: organizationalUnitId};
        PostAPI(ApiURLs.EmployeeBandList, parameters ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setBandList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const handleBusinessHeadChange = (event, value) => {
        const isAllSelected = value.some(item => item.employeeName === 'ALL');

        if (isAllSelected) {
            const allValuesExceptAll = businessHeadList.filter(item => item.employeeName !== 'ALL');
            setSelectAllBUHead(allValuesExceptAll);
            setSelectedBusinessHead([{ employeeId: 1, employeeName: 'ALL' }]);
        } else {
            const uniqueValues = Array.from(new Set(value.map(item => item.employeeId)))
                .map(employeeId => value.find(item => item.employeeId === employeeId));
            setSelectedBusinessHead(uniqueValues);
            setSelectAllBUHead(uniqueValues);
        }
    };

    const handleBandChange = (event, value) => {
        const isAllSelected = value.some(item => item.band === 'ALL');
        if (isAllSelected) {
            const allValuesExceptAll = bandList.filter(item => item.band !== 'ALL');
            setSelectAllBand(allValuesExceptAll);
            setSelectedBand([{ band: 'ALL' }]);
        } else {
        const uniqueValues = Array.from(new Set(value.map(item => item.band)))
            .map(band => value.find(item => item.band === band));
        
        setSelectedBand(uniqueValues);
        setSelectAllBand(uniqueValues);
    }
    };

    const onClear = () => {
        setSelectedBusinessHead([]);
        setSelectAllBUHead([]);
        setSelectedBand([]);
        setSelectAllBand([]);
        setEndDate(dayjs(new Date()));
        setStartDate(dayjs(new Date()).add(-1, 'day').set('date', 1));

    }

    const onApply = () => {
        if (endDate.isAfter(dayjs(new Date())))
        {
            setToDateError(1);
            CustomAlert('End date cannot be greater than today', 'error');
            return;
        }
        if (endDate.diff(startDate, 'day') > 365) {
            CustomAlert('Date range cannot exceed 1 year', 'error');
            return;
        }
        props.setFilterModel({
            selectedCountry: countryId,
            selectedDivision: divisionId,
            selectedAccount: accountId,
            selectedBusinessUnit: organizationalUnitId,
            selectedBusinessHead: selectAllBUHead.map(item => item.employeeId),
            selectedBand: selectAllBand.map(item => item.band),
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            selectedPieChartName: PieChartName,
        });
    }

    return (
        <>
        <Card variant="outlined">
            <CardContent style={{ display: 'flex', alignItems: 'center', paddingBottom: 10, paddingLeft: 10 }}>
            <Grid container spacing={1} style={{ width: '100%' }}>
                <Grid item xs={3} style={{ marginTop: 2 }}>           
                <Autocomplete
                    multiple
                    size="small" 
                    id="tags-outlined"
                    options={businessHeadList}
                    getOptionLabel={(option) => option.employeeName}
                    filterSelectedOptions
                    value={selectedBusinessHead}
                    onChange={handleBusinessHeadChange}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Business Head"
                        placeholder="Business Head"
                    />
                    )}
                />
                </Grid>
                <Grid item xs={2} style={{ marginTop: 2 }}>           
                <Autocomplete
                    multiple
                    size="small" 
                    id="tags-outlined"
                    options={bandList}
                    getOptionLabel={(option) => option.band}
                    filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Band"
                        placeholder="Band"
                    />
                    )}
                    value={selectedBand}
                    onChange={handleBandChange}
                />
                </Grid>
                <Grid item style={{ paddingTop: 0 }}>
                    <Grid container sx={{ paddingTop: 0, marginTop: 0 }}>
                        <Grid item >
                            <UcDatesFilter disableFutureDates={disableFutureDates} minfromdate={minfromdate} maxfromdate={maxfromdate} mintodate={mintodate} maxtomdate={maxtomdate} setFromDateError={setFromDateError} fromDateError={fromdateerror}
                                setToDateError={setToDateError} toDateError={todateerror} fromDate={startDate} setFromDate={setStartDate} toDate={endDate} setToDate={setEndDate} ></UcDatesFilter>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} style={{ marginTop: 2, marginLeft: 10}}>
                <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 15, width: 150}} onClick={onApply}>Apply</Button> 
                </Grid>
            </Grid>          
            </CardContent>
        </Card>
        </>
    );
}