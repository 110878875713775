import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Card, Button,TextField, Divider,Grid, Typography,Box,InputLabel, MenuItem, Select,FormControl} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import UcEmployeeFilterActions from './UcEmployeeFilterActions';


export default function UcUserAcessAddNewUser(props) {
    const [employees, setEmployees] = useState();
    const [selectFilterError, setSelectFilterError] = React.useState(0);
    const [role, setRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [Country, setCountry] = useState('');
    const [Division, setDivision] = useState('');
    const [BusinessUnit, setBusinessUnit] = useState('');
    const [fullName, setFullName] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [errors, setErrors] = useState({});
    const newErrors = {};

    const employeesSelected = (value) => { 
        if (value !== null) {
            setEmployees(value);
            getEmployeeDetailsById(value);
        }
        else {
            setEmployees('');
            setCountry('');
            setDivision('');
            setBusinessUnit('');
            setEmployeeId('');
            setFullName('');
        }
    }

    useEffect(() => {
        getGroupList();
      }, []);
  
      
    const getGroupList = () => {
    setLoading(true);
    var url = ApiURLs.GroupList; 
    GetAPI(url).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            setRole(response);           
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    }

    const getEmployeeDetailsById = (empId) => {
        setLoading(true);
        var url = ApiURLs.EmployeeDetailsById + '?employeeId=' + empId; 
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setCountry(response.country);
                setDivision(response.division);
                setBusinessUnit(response.organizationalUnit);
                setEmployeeId(response.employeeId);
                setFullName(response.firstName + ' ' + response.lastName); 
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    }
    const AddUserRoleMapping = () => {
        setLoading(true);
        var parameters = {employeeId: employees, groupId: selectedRole};
        PostAPI(ApiURLs.AddUserRoleMapping,parameters).then((response) => { 
          setLoading(false);  
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                }
                else if (response.error === 400) {
                      CustomAlert(response.msg, 'error');
                }
              }                 
            else {               
                  CustomAlert(response.message, 'success'); 
                  props.onClose();
            }       
            })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      }
   
    const handleRoleSelectChange = (event) => {
        if (errors.selectedRole) {
            delete errors.selectedRole;
        }
        const selectedValue = event.target.value;
        setSelectedRole(selectedValue);
    }; 
    const errorStyle = {
        borderColor: 'red',
        borderWidth: 1,
    };
    const onSubmit = () => { 
        if (!selectedRole) newErrors.selectedRole = 'Role is required';
        if (!employees) {
            setSelectFilterError(1);
        }
        else {
            setSelectFilterError(0);
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0 && employees) {
            AddUserRoleMapping();
        }
    };

    return (
    <>
        <Box p={3}> 
            <Grid container justifyContent="flex-end">
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
                <CloseIcon className='App-Icons'/>
                </IconButton>
            </Grid>
            <Typography variant="h6" component="h6">
                Add New User
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{marginTop:2}}>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedRole}>
            <InputLabel style={errors.selectedRole ? errorStyle : {}}>Role</InputLabel>
            <Select  
                value={selectedRole}
                onChange={handleRoleSelectChange}      
                label="Role"
                style={errors.selectedRole ? errorStyle : {}}
            >
                <MenuItem value="" disabled>Select Role</MenuItem>
                {role.map((row) => (
                <MenuItem key={row.id} value={row.id}>{row.groupName}</MenuItem>
                ))}
            </Select>  
            {errors.selectedRole && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedRole}</div>}                            
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <UcEmployeeFilterActions selectFilterError={selectFilterError} setSelectFilterError={setSelectFilterError} employeesSelected={employeesSelected}></UcEmployeeFilterActions>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth 
                value={Country}
                label="Country"
                variant="outlined"
                size="small"
                disabled            
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth 
                value={Division}             
                label="Division"
                variant="outlined"
                size="small"
                disabled           
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth
                value={BusinessUnit}
                label="Business Unit"
                variant="outlined"
                size="small"
                disabled
            />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                fullWidth 
                value={employeeId}          
                label="Employee ID"
                variant="outlined"
                size="small"
                disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                fullWidth  
                value={fullName}         
                label="Full Name"
                variant="outlined"
                size="small"
                disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'none', fontSize: 15, width: 120 }}
                onClick={onSubmit}
                className='App-Button'
                >
                Add
                </Button>
            </Grid>
            </Grid>
        </Box>
    </>
    );
}