import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Card, Tooltip,Switch, Button,TextField, Divider,Grid, Typography,Box,InputLabel, MenuItem, Select,FormControl,Paper,List,ListItemButton,ListItemIcon,Checkbox,ListItemText} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PutAPI } from '../../Utilities/API/PutAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import '../../../src/App.css';

export default function UcBusinessHeadUpdate(props) {
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [headEmployeeId, setHeadEmployeeId] = useState('');
    const [headEmployeeName, setHeadEmployeeName] = useState('');
    const [rowId, setRowId] = useState(0);
    const label = { inputProps: { 'aria-label': 'Enable/Disable' } };
    const [checked, setChecked] = useState(true);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const newErrors = {};

    useEffect(() => {
        getCountryList();
    }, [selectedCountry]);
  
    useEffect(() => {   
        if (props.data.countryId !== 0 && selectedCountry === '') { 
            setRowId(props.data.id || 0);
            setSelectedCountry(props.data.countryId || '');
            setHeadEmployeeId(props.data.headEmployeeId || '');
            setHeadEmployeeName(props.data.headEmployeeName || '');
            if(props.data.active === 'Inactive')
            {
                setChecked(false);
            }
            else
            {
                setChecked(true);
            }
            getDivisionList(props.data.countryId);
        }
      }, [props.data.countryId]);
  
      useEffect(() => {    
        if(props.data.divisionId !== 0 && selectedDivision === ''){ 
          setSelectedDivision(props.data.divisionId || '');
          getBusinessUnitList(props.data.divisionId,props.data.countryId);
        }
      }, [props.data.divisionId ]);

      useEffect(() => {
        if (props.data.organizationalUnitId !==  0 && selectedBusinessUnit === '') {
            setSelectedBusinessUnit(props.data.organizationalUnitId);
        }
    }, [props.data.organizationalUnitId]);

     const  getCountryList =  () => {
        setLoading(true);
        var url = ApiURLs.GetCountryList; 
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setCountryList(response);
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      }
      const getDivisionList =  (selectedValue) => {   
        setLoading(true);
        var url = ApiURLs.GetDivisionList + '?countryId=' + selectedValue;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setDivisionList(response);           
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      }
      const getBusinessUnitList =  (selectedDivisionValue,selectedCountryValue) => {
        setLoading(true); 
        var url = ApiURLs.GetBusinessUnitList +'?divisionId=' + selectedDivisionValue + '&countryId=' + selectedCountryValue;
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setBusinessUnitList(response);        
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      }  
      const handleCountrySelectChange = (event) => {
        if (errors.selectedCountry) {
            delete errors.selectedCountry;
        }
        const selectedValue = event.target.value;
        
        if (selectedCountry !== selectedValue) {
            setSelectedCountry(selectedValue);
            setSelectedDivision('');
            setSelectedBusinessUnit('');
            setDivisionList([]);
            setBusinessUnitList([]);

            if (selectedValue && selectedValue !== ' ') {
                getDivisionList(selectedValue);
            }
        }
    }; 
    const handleDivisionSelectChange = (event) => {
        if (errors.selectedDivision) {
            delete errors.selectedDivision;
        }
        const selectedDivisionValue = event.target.value;
        setSelectedDivision(selectedDivisionValue);
        setSelectedBusinessUnit('');
        setBusinessUnitList([]);
        if (selectedDivisionValue && selectedDivisionValue !== ' ') {
            getBusinessUnitList(selectedDivisionValue,selectedCountry);
        }
    };
    const handleBusinessUnitSelectChange = (event, newValue) => {
        if (errors.selectedBusinessUnit) {
            delete errors.selectedBusinessUnit;
        }
        setSelectedBusinessUnit(event.target.value);
    }; 
    const errorStyle = {
        borderColor: 'red',
        borderWidth: 1,
    };
    const onUpdate = () => { 
        const newErrors = {};
          if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
          if (!selectedDivision) newErrors.selectedDivision = 'Division is required';
          if (!selectedBusinessUnit) newErrors.selectedBusinessUnit = 'Business unit is required';
          if (!headEmployeeId) newErrors.headEmployeeId = 'BU head Emp ID is required';
          if (!headEmployeeName) {
            newErrors.headEmployeeName = 'BU head Full Name is required';
          } 
        setErrors(newErrors);
    
        if (Object.keys(newErrors).length === 0) {
          updateBussinessUnitHeadDetails();
        } 
      }
      const handleEmployeeName = (e) => {
        const { value } = e.target;
        const newErrors = {};
        // Validate if the input only contains letters and spaces
        const regex = /^[A-Za-z\s]*$/;
        if (!regex.test(value)) {
          newErrors.headEmployeeName = 'Only letters and spaces are allowed';
        } else if (value.length > 200) {
          newErrors.headEmployeeName = 'Character limit is exceeded';
        } else {
          setHeadEmployeeName(value);
        }
    
        setErrors(newErrors);
      };
      const handleEmployeeId = (e) => {
        const { value } = e.target;
        const newErrors = {};
        // Validate if the input only contains letters and spaces
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
          newErrors.headEmployeeId = 'Only digits are allowed';
        } else if (value.length > 10) {
          newErrors.headEmployeeId = 'Digits limit is exceeded';
        } else {
          setHeadEmployeeId(value)
        }
    
        setErrors(newErrors);
      };
      const updateBussinessUnitHeadDetails = () => {
        setLoading(true);
        var url = ApiURLs.UpdateBusinessUnitHeadDetails;
        var model = { id: rowId, countryId:selectedCountry,divisionId:selectedDivision,organizationalUnitId:selectedBusinessUnit,headEmployeeId:headEmployeeId,headEmployeeName:headEmployeeName,active:checked ? 1 : 0 };
        PutAPI(url, model).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                CustomAlert(response.message, 'success');
                props.onClose();  
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      
      };
      const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
      };

    return (
        <>
        <Box p={3}> 
            <Grid container justifyContent="flex-end">
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
                <CloseIcon />
                </IconButton>
            </Grid>
            <Typography variant="h6" component="h6" gutterBottom>
                Update Business Head
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{marginTop:2}}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small" required>
                <InputLabel>Country</InputLabel>
                <Select
                  value={selectedCountry}
                  onChange={handleCountrySelectChange}
                  label="Country"
                >
                  {
                    countryList.map((row, index) => {
                        return (
                            <MenuItem style={{ fontSize: 13, padding: 2, paddingLeft: 6 }} key={row.id} value={row.id}>{row.country}</MenuItem>
                        )
                    })
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small" required>
                <InputLabel>Division</InputLabel>
                <Select
                 value={selectedDivision}
                  onChange={handleDivisionSelectChange}
                  label="Division"
                >
                {
                    divisionList.map((row, index) => {
                        return (
                            <MenuItem style={{ fontSize: 13, padding: 2, paddingLeft: 6 }} key={row.id} value={row.id}>{row.division}</MenuItem>
                        )
                    })
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small" required>
              <InputLabel>Business unit</InputLabel>
              <Select             
                value={selectedBusinessUnit}
                onChange={handleBusinessUnitSelectChange}
                label="Business unit"
              >
                {
                  businessUnitList.map((row, index) => {
                      return (
                          <MenuItem style={{ fontSize: 13, padding: 2, paddingLeft: 6 }} key={row.id} value={row.id}>{row.organizationalUnit}</MenuItem>
                      )
                  })
              }
              </Select>
            </FormControl>
            </Grid>           
            <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={headEmployeeId}
                  onChange={handleEmployeeId}
                  label="BU head Emp ID"
                  variant="outlined"
                  size="small"
                  required
                  error={!!errors.headEmployeeId}
                  helperText={errors.headEmployeeId}
                  style={errors.headEmployeeId ? errorStyle : {}}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={headEmployeeName}
                  onChange={handleEmployeeName}
                  label="BU head Full Name"
                  variant="outlined"
                  size="small"
                  required
                  error={!!errors.headEmployeeName}
                  helperText={errors.headEmployeeName}
                  style={errors.headEmployeeName ? errorStyle : {}}
                  inputProps={{ maxLength: 200}}
                />
            </Grid>           
            <Grid item xs={12} sm={6}>                       
              <Tooltip title = 'Active/Inactive'><Switch {...label} checked={checked} onChange={handleSwitchChange}/></Tooltip>            
              <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 15, width: 120, marginLeft: '15px'}} onClick={onUpdate}>Update</Button>
            </Grid>
            </Grid>
            </Box>
        </>
    )
}
