import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import { PostAPI } from '../Utilities/API/PostAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export default function DashboardWeeklyTrendwise(props) {
    const previousController = useRef(null);
    const [trendFeedbackCount, setTrendFeedbackCount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentWeekStart, setCurrentWeekStart] = useState(dayjs().startOf('week'));
    const [currentWeekEnd, setCurrentWeekEnd] = useState(dayjs().endOf('week'));
    const [xLabels, setXLabels] = useState([]);

    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;

    useEffect(() => {
        if (countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null) {
            getFeedbackCountTrend();
        }
    }, [props.filterModel, currentWeekStart, currentWeekEnd]);

      const getFeedbackCountTrend = () => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, 
            startDate: currentWeekStart.format('YYYY-MM-DD'), 
            endDate: currentWeekEnd.format('YYYY-MM-DD') };
        PostAPI(ApiURLs.FeedbackCountTrend, parameters, null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
        else {
            if (response.length > 0) {
                setTrendFeedbackCount(response); // Set series data
                
                const labels = response.map(item => dayjs(item.dates).format('DD/MM/YYYY'));

                if (labels.length === response.length) {
                    setXLabels(labels); // Set x-axis labels
                } else {
                    console.error('Data length mismatch between xLabels and series');
                }
            } else {
                setXLabels([]); // Clear x-axis if there's no data
                setTrendFeedbackCount([]); // Clear series if there's no data
            }
        }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };

    // const updateXAxisLabels = (data) => {
    //     let labels = data.map(item => {
    //         const formattedDate = dayjs(item.dates).format('DD/MM/YYYY');
    //         return formattedDate;
    //     });
    //     setXLabels(labels);
    // };
    const handlePreviousWeek = () => {
        const prevWeekStart = currentWeekStart.subtract(1, 'week');
        const prevWeekEnd = currentWeekEnd.subtract(1, 'week');
        setCurrentWeekStart(prevWeekStart);
        setCurrentWeekEnd(prevWeekEnd);
        getFeedbackCountTrend();
    };

    const handleNextWeek = () => {
        const nextWeekStart = currentWeekStart.add(1, 'week');
        const nextWeekEnd = currentWeekEnd.add(1, 'week');
        setCurrentWeekStart(nextWeekStart);
        setCurrentWeekEnd(nextWeekEnd);
        getFeedbackCountTrend();
    };

    const getFormattedWeekDates = () => {
        let dates = [];
        let date = currentWeekStart;
        while (date.isBefore(currentWeekEnd) || date.isSame(currentWeekEnd)) {
            dates.push(date.format('DD/MM/YYYY'));
            date = date.add(1, 'day');
        }
        return dates.map((date, index) => (
        <span key={index} style={{ marginRight: '50px' }}>
            {date}
        </span>
    ));
    };

    const happyData = trendFeedbackCount.map(item => item.happy);
    const neutralData = trendFeedbackCount.map(item => item.neutral);
    const unhappyData = trendFeedbackCount.map(item => item.unhappy);
    const headCount = trendFeedbackCount.map(item => item.headCount);
    //const totalResponse = trendFeedbackCount.map(item => item.totalResponse);

    //const xLabels = trendFeedbackCount.map(item => new Date(item.dates).toLocaleDateString());


    return (
        <Card variant="outlined" sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography  className='App-FontColor' style={{fontSize:'20px', margin: '2%' }}>
                    Pulse by Weekly Trend
          </Typography>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}> 
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>         
                    <IconButton onClick={handlePreviousWeek} color='primary'>
                            <KeyboardArrowLeftIcon className="App-Icons" fontSize="small" />
                    </IconButton>
                    <Typography className='App-FontColor' style={{fontSize:'15px' }}>
                        {getFormattedWeekDates()}
                    </Typography>
                    <IconButton onClick={handleNextWeek} color='primary'>
                            <KeyboardArrowRightIcon className="App-Icons" fontSize="small" />
                    </IconButton>
                </div>         
                {loading ? (
                     <CircularProgress />
                ) :  (
                <LineChart
                    width={1000}
                    height={250}
                    series={[
                        { data: happyData, label: 'Happy' },
                        { data: neutralData, label: 'Neutral' },
                        { data: unhappyData, label: 'Unhappy' },
                        { data: headCount, label: 'Head Count' },
                        //{ data: totalResponse, label: 'Total Response' }
                    ]}
                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                    //margin={{ right: 20, left: 60 }}
                    style={{ margin: '0 auto', display: 'block' }}
                    />
                )}
            </CardContent>
            <Typography style={{fontStyle:'italic', fontSize:'12px', padding: '8px' }}>
                Note: Graph is based on the total responses received.
            </Typography>
        </Card>
    );
}