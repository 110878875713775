import React, { useEffect, useState } from 'react';
import {useSelector } from 'react-redux'
import { Card, Typography,Tooltip} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {GetAPI} from '../Utilities/API/GetAPI';
import {PostAPI} from '../Utilities/API/PostAPI';
import {ApiURLs} from '../Utilities/API/ApiURLs';
import {CustomAlert} from '../Utilities/CustomAlert';
import { setupNotifications } from '../firebasenotifications/firebase';
import {IsNullOrEmptyOrUndefined} from '../Utilities/Utilities';
import '../../src/App.css'


export function Home() {
  const [selectedImage, setSelectedImage] = useState(0);
  const [feedbackTypeDetails, setFeedbackTypeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fisttimeload, setFirstTimeLoad] = useState(true);
  const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);

  const images = require.context('../Images/', true);

  const imageList = images.keys().map(image => ({
        src: images(image),
        name: image.replace('./', '').replace(/\.[^/.]+$/, '')
  }));
  

  useEffect(() => {
    setupNotifications((message) => {
      console.log(message);
    });
  }, []);

  useEffect(() => {
    if(fisttimeload == true){
      getFeedbackTypeDetails();
    }
  });

  const getFeedbackTypeDetails = () => {
    setFirstTimeLoad(false);
    setLoading(true);
    GetAPI(ApiURLs.FeedbackTypeDetails).then((response) => { 
      setLoading(false);   
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
            }
            else
                if (response.error === 400) {
                    CustomAlert(response.msg, 'error');
                }
                else {
                    CustomAlert(response.msg, 'error');
                }
        }
        else {
            setFeedbackTypeDetails(response.feedbacks); 
            setSelectedImage(response.selectedFeedbackId);
          }
        })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
  }
    const getEmployeeFeedback = (imgId) => {
      setLoading(true);
      var parameters = { feedbackTypeId: imgId }
      PostAPI(ApiURLs.EmployeeFeedback,parameters).then((response) => { 
        setLoading(false);  
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
              if (response.error === 404) {
                  CustomAlert('Unauthrize error, try to login again', 'error');
              }
              else
                  if (response.error === 400) {
                      CustomAlert(response.msg, 'error');
                  }
                  else {
                      CustomAlert(response.msg, 'error');
                  }
          }
          else {
              if(response.statusCode!= 0){
                CustomAlert(response.message, 'success'); 
              }
              else{
                CustomAlert(response.message, 'error');                
              }
            }
          })
          .catch((e) => {
              setLoading(false);
              CustomAlert(e, 'error');
          });
  }


      const handleImageClick = (imgId) => {
        if(selectedImage !== imgId){
          getEmployeeFeedback(imgId);
          setSelectedImage(imgId);
        }
        
      };
    return (
      <Card variant="outlined" style={{ marginTop: 10, width: '100%', height: '82vh' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '3%' }}>
        <Typography className="App-FontColor" style={{ fontSize: 30, fontFamily: 'Serif' }}>What's your mood today?</Typography>
        <ImageList       
            sx={{
                width: '100%', // Ensure the width is 100%
                maxWidth: 750, // Add a max width to limit the size if necessary
                marginTop: '2%',              
            }}
            cols={3}
            rowHeight={200} // Adjust row height to increase image size   
            gap={30} // Add some gap between the images
        >
       {feedbackTypeDetails.map((item) => {
            const matchedImage = imageList.find(image => image.name === item.feedbackTypeUrl.replace(/\.[^/.]+$/, ''));
            return (
              <Tooltip key={item.id} title={item.feedback} arrow>
                <ImageListItem
                  key={item.id}
                  onClick={() => handleImageClick(item.id)}
                  sx={{
                    padding: '15px',   
                    cursor: 'pointer',
                    border: selectedImage === item.id ? '2px solid #4caf50' : 'none',
                    borderRadius: '10%',
                    overflow: 'hidden', // Hide any overflow from the zoom
                  }}
                >
                  {matchedImage && (
                    <img
                      srcSet={`${matchedImage.src}?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
                      src={`${matchedImage.src}?w=200&h=200&fit=crop&auto=format`}
                      alt={item.feedback}  
                      style={{
                        width: '100%',
                        height: 'auto',
                        transition: 'transform 0.3s', // Add transition for smooth zoom-in
                        transform: 'scale(1)', // Default scale
                        opacity: selectedImage === item.id ? 0.7 : 1,
                      }}
                      onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'} // Zoom in effect on hover
                      onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} // Reset on mouse out
                    />
                  )}
                </ImageListItem>
                <Typography className="App-FontColor" style={{ fontSize: 20, fontFamily: 'Serif', textAlign: 'center' }}>
                  {item.display}
                </Typography>
              </Tooltip>
            );
          })}
        </ImageList>
        </div>
      </Card>
    );
}
