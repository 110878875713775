import React, { useState, useEffect,useRef } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Card, CardContent, Typography } from '@mui/material';
import { PostAPI } from '../Utilities/API/PostAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';


export default function DashboardBandwise(props) {
  const previousController = useRef(null);
  const [bandFeedbackCount, setbandFeedbackCount] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;
    var buHeadIds = props.filterModel.selectedBusinessHead;
    var bands = props.filterModel.selectedBand;
    var startDate = props.filterModel.startDate;
    var endDate = props.filterModel.endDate;

    useEffect(() => {
        if(countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null) {
          getFeedbackCountByBand();
        }
    }, [props.filterModel]);

      const getFeedbackCountByBand = () => {
        if (previousController.current) {
          previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        PostAPI(ApiURLs.FeedbackCountByBand, parameters, null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
              if (response.length > 0) {
                setbandFeedbackCount(response);
              }
              else {
                setbandFeedbackCount([]);
            }
          }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };



    const transformedData = bandFeedbackCount.map((item) => {
      const data = { band: item.band };
      item.feedbacks.forEach(feedback => {
        data[feedback.feedback.toLocaleLowerCase()] = feedback.totalCount;
      });
      return data;
    });    

      const chartSetting = {
        width: 1000,
        height: 250,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
          },
        },
      };
      
    const valueFormatter = (value) => `${value}`;

     // Extract unique feedback types from the dataset
     const feedbackTypes = Array.from(
      new Set(bandFeedbackCount.flatMap(item => item.feedbacks.map(feedback => feedback.feedback.toLowerCase())))
    );

    // Create series configuration dynamically
    const series = feedbackTypes.map(type => ({
      dataKey: type,
      label: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the first letter
      valueFormatter,
    }));  

    return (
      <Card variant="outlined" sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography  className='App-FontColor' style={{fontSize:'20px', margin: '2%' }}>
              Pulse by Band
          </Typography>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>            
                {loading ? (
                     <CircularProgress />
                ) :  (
                <BarChart
                    dataset={transformedData}
                    xAxis={[{ scaleType: 'band', dataKey: 'band' }]}
                    series={series}
                    {...chartSetting}
                    margin={{ right: 20, left: 60, }}
                    />
                )}
            </CardContent>
            <Typography style={{fontStyle:'italic', fontSize:'12px', padding: '8px' }}>
                Note: Graph is based on the total responses received. <br/>
                <strong>-</strong> : Hyphen represents no band
            </Typography>           
        </Card>
    );
}