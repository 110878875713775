import EditIcon from '@mui/icons-material/Edit';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import { Button, Divider,FormControl, Select, MenuItem,Card, InputLabel,Typography,CardContent, Grid, Tooltip,Dialog,IconButton,TextField,Box,InputAdornment, Hidden} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add'
import  UcAccountMappingAdd  from './UcAccountMappingAdd';
import '../../../src/App.css';
import UcAccountMappingUpdate from './UcAccountMappingUpdate';
import dayjs from 'dayjs';
import { Constant } from '../../Utilities/Constant';


export function UcAccountMapping() {
    const previousController = useRef();
    const [openrequest, setopenrequest] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [searchtext, setsearchtext] = useState('');
    const [isDatafound, setIsDatafound] = React.useState(true);
    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 0,
        pageSize: 10
    });
    const [parameters, setparameters] =  useState(
    {
        id:0,
        countryId:'',
        divisionId:'',       
        organizationalUnitId:'',
        active:0
    });
    useEffect(() => {
        if (searchtext.length >= 3) {
            getBUAccountMappingList(false);
        }
        else if (searchtext.length == '' || searchtext.length == 0 || searchtext.length == null) {
            getBUAccountMappingList(true);
        }
    }, [searchtext])

    const handleChange = (e) => {
        setsearchtext(e.target.value);
    }

    useEffect(() => {
        setIsDatafound(true)
        getBUAccountMappingList(true);
    }, [pageState.page, pageState.pageSize]);
    
    const handleopen = () => {
        setopenrequest(true); 
    };
    const handleClose = () => {
        setopenrequest(false);
        getBUAccountMappingList();
    };
    const getBUAccountMappingList = (flag) => {
        if (previousController.current) {
          previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
    
    
        setLoading(true);
        setPageState(old => ({ ...old, isLoading: true }))
        if (IsNullOrEmptyOrUndefined(searchtext) === false && flag != true) {
          setPageState(old => ({ ...old, page: 0, pageSize: 10 }));
        }
        var parameters = { searchTerm: searchtext, pageSize: pageState.pageSize, pageNum: pageState.page===0?1:(pageState.page+1) };
        PostAPI(ApiURLs.BUAccountMappingList, parameters, null, signal).then((response) => {
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
              CustomAlert('Unauthrize error, try to login again', 'error');
            } else {
              CustomAlert(response.msg, 'error');
            }
          }
          else {
            setPageState(old => ({ ...old, isLoading: false, data: response, total: response.length > 0 ? response[0].totalCount : 0 }))
            if (response.length > 0) {
              setIsDatafound(true);
            }
            else if (response.length === undefined) {
              setPageState(old => ({ ...old, isLoading: true }))
            }
            else {
              setIsDatafound(false);
            }
          }
        })
          .catch((e) => {
            setLoading(false);
            setPageState(old => ({ ...old, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 }));
            CustomAlert(e, 'error');
          });
    };
    const handleopenUpdate = (row) => { 
        setOpenUpdate(true);
        //const organizationalUnitIds = row.organizationalUnitList.map(unit => unit.id);
        setparameters({accountId:row.id, accountName: row.account, countryId:row.countryId, divisionId:row.divisionId, organizationalUnitId:row.organizationalUnitList, active:row.active}); 
    };
    const handleUpdateClose = () => {
        setOpenUpdate(false);
        getBUAccountMappingList();
    };  
    const columns = [
        //{ field: 'id', headerName: 'Id',  hide:true},
        {
            field: 'country', headerName: 'Country', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.country}>
               <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                 {IsNullOrEmptyOrUndefined(cell.row.country) === true ? "" : cell.row.country}
                </Typography></Tooltip>);
            },
        },
        {
          field: 'division', headerName: 'Division', headerAlign: 'left', align: 'left', sortable: false, width: 200,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.division}>
               <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.division) === true ? "" : cell.row.division}
                </Typography></Tooltip>);
          },
        },
        {
            field: 'account', headerName: 'Account', headerAlign: 'left', align: 'left', sortable: false, width: 150,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.account}>
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                    {IsNullOrEmptyOrUndefined(cell.row.account) === true ? "" : cell.row.account}
                </Typography></Tooltip>);
            },
        },
        
        {
            field: 'createdBy', headerName: 'Created By', headerAlign: 'left', align: 'left', sortable: false, width: 180,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.createdBy}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.createdBy) === true ? "" : cell.row.createdBy}</Typography></Tooltip>);
            },
        },
        {
            field: 'createdDate', headerName: 'Created Date', headerAlign: 'left', align: 'left', sortable: false, width: 120,
            renderCell: (cell) => {
                return (<Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.createdDate) === false ? dayjs(cell.row.createdDate).format(Constant.dateformat) : ""}</Typography>);
            },
        },
        {
            field: 'updatedBy', headerName: 'Updated By', headerAlign: 'left', align: 'left', sortable: false, width: 180,
            renderCell: (cell) => {
              return (<Tooltip title={cell.row.updatedBy}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.updatedBy) === true ? "" : cell.row.updatedBy}</Typography></Tooltip>);
            },
        },
        {
            field: 'updatedDate', headerName: 'Updated Date', headerAlign: 'left', align: 'left', sortable: false, width: 120,
            renderCell: (cell) => {
                return (<Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.updatedDate) === false ? dayjs(cell.row.updatedDate).format(Constant.dateformat) : ""}</Typography>);
            },
        },
        {
            field: 'active', headerName: 'Status', headerAlign: 'left', align: 'left', sortable: false, width: 100,
            renderCell: (cell) => {
                return (<Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.active) === true ? "" : cell.row.active}</Typography>);
            },
        },
        {
            field: 'organizationalUnitList', headerName: 'Business Unit', headerAlign: 'center', align: 'center', sortable: false, width: 120,
            renderCell: (cell) => {
                const businessUnitList = cell.row.organizationalUnitList || []; 
                const tooltipContent = businessUnitList.length ? (
                    <div style={{ listStyleType: 'disc', paddingLeft: '5px' }}>
                        {businessUnitList.map((unit, index) => (
                         <div key={index} style={{ marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '8px' }}>&#8226;</span> {unit.organizationalUnit}
                        </div>
                        ))}
                    </div>
                ) : 'No business units available';
                return (
                    <Tooltip title={tooltipContent}>
                        <IconButton color='primary' style={{ padding: 2 }}>
                            <VisibilityIcon className="App-Icons" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: 'action', headerName: 'Action', headerAlign: 'center', align: 'center', sortable: false, width: 100,
            renderCell: (cell) => {
            return (<Tooltip title="Edit"><IconButton color='primary' style={{ padding: 2}} onClick={() => handleopenUpdate(cell.row)}><EditIcon className="App-Icons" fontSize="small" /></IconButton></Tooltip>);
            },
        },      
    ] 
  return (
    <>
    <Card variant="outlined" style={{ marginTop: 10 }} >
        <CardContent style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
            <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 12, width: 150, marginLeft: 10}} onClick={handleopen} startIcon={<AddIcon style ={{fontSize: 16,marginRight: -4}} />}>Add Account</Button>           
        </CardContent>
    </Card>
    <Card variant='outlined' style={{ marginTop: 5, marginBottom: 15, minHeight: 300 }}>              
        <div style={{ paddingLeft: '80%', paddingBottom: '1%' }}>
            <TextField style={{ width: '80%' }}
                placeholder='Search...'
                value={searchtext}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon className="App-Icons"/>
                        </InputAdornment>
                    ),
                }}
                variant="standard"
            />
        </div>
        {(pageState.data.length > 0 || pageState.data.length === undefined) &&
            <Box sx={{ height: 350, width: '100%' }}>
                    <DataGrid
                    getRowId={(row) => row.id}
                    disableColumnMenu
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pageSizeOptions={[10, 30, 50, 70, 100]}
                    pagination                    
                    paginationMode="server"
                    onPaginationModelChange={(e) => {
                    setPageState(old => ({ ...old, page: e.page, pageSize:e.pageSize}))
                    }}
                    paginationModel={pageState}
                    columns={columns}
                    getRowHeight={() => 'auto'} headerHeight={31}
                    className="App-Icons"
                    sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 } }}
                />
            </Box>
        }
        {isDatafound === false &&
            <Box sx={{ height: 250, width: '100%', paddingTop: 20 }}>
                <div sx={{ fontSize: 13, align: 'center' }}>
                    <center>No record found</center>
                </div>
            </Box>
        }              
    </Card>
    <Dialog
        open={openrequest} 
        onClose={handleClose}
        fullWidth={true} height={1000} maxWidth={'md'}
        >
        <UcAccountMappingAdd
            open={openrequest}
            onClose={handleClose}
        />
    </Dialog>
    <Dialog
        open={openUpdate}
        onClose={handleUpdateClose}
        fullWidth={true} height={1000} maxWidth={'md'}
        >
        <UcAccountMappingUpdate
            open={openUpdate}
            onClose={handleUpdateClose}
            data={parameters}
        />
    </Dialog>
    </>
  );
}